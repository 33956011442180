import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";

const About = ({ data, location }) => {
    let global = data.contentfulWebsite;
    return (
        <Layout pathname={location.pathname}>
            <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-container o-content o-content-container o-content-container--large u-text-center">
                {data.contentfulAboutPage.primaryCards.map((column, index) =>
                    <img className="u-border" key={index} alt={column.title} src={column.image.resize.src} />
                )}
                <div className="h2 u-text-normalcase">{data.contentfulAboutPage.primaryContent.primaryContent}</div>
                <hr />
                {data.contentfulAboutPage.secondaryCards.map((column, index) =>
                    <img key={index} className="c-logo c-logo--body" alt={column.title} src={column.image.file.url} />
                )}
                <div className="h2 u-text-normalcase">{data.contentfulAboutPage.secondaryContent.secondaryContent}</div>
                <hr />
            </div>
        </Layout>
    );
};

export default About

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "UNINTERRUPTED Canada"}) {
        author
        description
        title
        shareImage {
            file {
                url
            }
        }
    }
    contentfulAboutPage(aboutId: {eq: "About Us"}, website: {title: {eq: "Black Ice"}}) {
        primaryContent {
            primaryContent
        }
        secondaryContent {
            secondaryContent
        }
        primaryCards {
            title
            image {
                resize(width: 1900) {
                    src
                }
            }
        }
        secondaryCards {
            title
            image {
                file {
                    url
                }
            }
        }
    }
}
`;
